import React, {
  useEffect,
  useContext,
  useReducer,
  forwardRef
} from 'react';

import MainContext from './main.context';
import { mainReducer, initialState } from './main.reducer';


import firebaseApp from '../../firebase';
import { SignOut } from '../utilities/firebase.actions';

import { useHistory } from 'react-router-dom';

const GlobalState = props => {

  const [ mainState, mainDispatch ] = useReducer(mainReducer,initialState);

  //  AUTH LISTENER
  useEffect(() => {

    const authListener = firebaseApp.auth().onAuthStateChanged( user => {

      if (user) {
        mainDispatch({ type: 'setAuthUser', payload: user })
        // verifyUser(user);

      } else {

        mainDispatch({ type: 'setAuthUser', payload: null })
        console.log('THERE IS NO USER.');

      }

    });


      return () => authListener();
  }, [])

  const verifyUser = async (user) => {
    console.log('Verifying user...');
    let options = {
      method: 'Post',
      body: JSON.stringify({
        uid: user.uid
      })
    }

    let url = "http://localhost:5000/approve-it-5c4fc/us-central1/checkUserClaim";

    try {

      let initCheckUser = await fetch(url,options);
      let textRes = await initCheckUser.text();

      if(initCheckUser.ok) {
        mainDispatch({ type: 'setUserType', payload: textRes });
        mainDispatch( {type: 'setLoading', payload: false });
        return;
      }

      alert(textRes);
      //  Sign user out
      SignOut();

    } catch (e) {

      console.log('THERE WAS AN ERROR',e);
      mainDispatch({ type: 'setLoading', payload: false })

    }

  }

  return (
    <MainContext.Provider
      value={{
        mainState: mainState,
        mainDispatch: mainDispatch
      }}
    >
    { props.children }
    </MainContext.Provider>
  )
}

export default GlobalState
