import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Drawer,
  List,
  Divider,
  TextField,
  Button,
  Typography,
  Switch
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import firebaseApp from '../../firebase';
import BackDrop from '../shared/backdrop.component';
import ErrorDialog from '../shared/error.dialog.component';
import MaterialTable from 'material-table';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3),
    marginTop: theme.spacing(20),
  },
  address: {
    padding: theme.spacing(1)
  }
}))

const SignUpListComponent = ({authUser}) => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(true);
  const [ signUpList, setList ] = useState([]);
  const [ showError, setShowError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  //  GET ALL USERS
  useEffect(() => {
    const usersListeners = firebaseApp.firestore().collection('signups')
      .onSnapshot(snapshot => {
        if(snapshot.size > 0) {
          let arr = snapshot.docs.map(doc => doc.data());
          console.dir(arr);
          setList([...arr]);
        } else {
          setList([])
        }
        setLoading(false);
      },error => {
        console.log('ERROR',error);
        setLoading(false);
      })
  },[])


  const handleSubmit = async (obj) => {
    console.log(obj);



    let options = {
      method: 'Post',
      headers: {
        Authorization: authUser.uid
      },
      body: JSON.stringify(obj)
    }
    let url = "https://us-central1-munchkin-notes.cloudfunctions.net/createNewUser";
    // url = "http://localhost:5000/munchkin-notes/us-central1/createNewUser";


    setLoading(true);

    try {

      let initCreateUser = await fetch(url,options);
      let textRes = await initCreateUser.text();
      console.log(textRes);
      setLoading(false);

      if(initCreateUser.ok) {
        return;
      }

      initShowError(textRes);

    } catch (e) {
      console.log('ERROR',e);
      setLoading(false);
      e.target.reset();
      initShowError('THERE WAS AN ISSUE CREATING USER.');
    }

  }

  const initShowError = message =>  {
    setErrorMessage(message);
    setShowError(true);
    setTimeout(() => setShowError(false),2000);
  }

  const columns = [
    {
      title: 'Company',
      field: 'company'
    },
    {
      title: 'Provider',
      render: rowData => <p>{rowData.last_name + ', ' + rowData.first_name}</p>
    },
    {
      title: 'Phone',
      field: 'phone'
    },
    {
      title: 'Address',
      render: rowData =>
        <div className={classes.address}>
          <p>{rowData.address.street}</p>
          <p>{rowData.address.city}</p>
          <p>{rowData.address.state}</p>
          <p>{rowData.address.zip}</p>
        </div>
    },
    {
      title: 'Action',
      render: rowData =>
        <div>
          <Button
            color='secondary'
            onClick={() => handleSubmit(rowData)}
          >
            Approve
          </Button>
        </div>
    }
  ]

  return (
    <div className={classes.root}>

      <MaterialTable
        title="Sign Ups"
        style={{
          width: '80%'
        }}
        columns={columns}
        data={signUpList}
      />

      <BackDrop loading={loading} />

      <ErrorDialog message={errorMessage} toOpen={showError} />
    </div>
  )
}

export default SignUpListComponent
