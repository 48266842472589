import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Drawer,
  List,
  Divider,
  TextField,
  Button,
  Typography,
  Switch
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import firebaseApp from '../../firebase';
import BackDrop from '../shared/backdrop.component';
import MaterialTable from 'material-table';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3),
    marginTop: theme.spacing(20),
  },
  address: {
    padding: theme.spacing(1)
  }
}))

const ProviderFormComponent = ({authUser}) => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(true);
  const [ providers, setProviders ] = useState([]);

  //  GET ALL USERS
  useEffect(() => {
    const usersListeners = firebaseApp.firestore().collection('users')
      .onSnapshot(snapshot => {
        if(snapshot.size > 0) {
          let arr = snapshot.docs.map(doc => doc.data());
          console.dir(arr);
          setProviders([...arr]);
        }
        setLoading(false);
      },error => {
        console.log('ERROR',error);
        setLoading(false);
      })
  },[])


  const handleSubmit = async (e) => {

  }

  const changeStatus = (currentStatus,ref) => {
    setLoading(true);

    firebaseApp.firestore().collection('users').doc(ref)
      .update({
        active: !currentStatus
      })
      .then(() => {
        console.log('Successfully changed status.');
        setLoading(false);
      })
      .catch(err => {
        console.log('ERROR',err);
        setLoading(false);
      })

  }

  const columns = [
    {
      title: 'Company',
      field: 'company'
    },
    {
      title: 'Provider',
      render: rowData => <p>{rowData.lastName + ', ' + rowData.firstName}</p>
    },
    {
      title: 'Phone',
      field: 'phone'
    },
    {
      title: 'Address',
      render: rowData =>
        <div className={classes.address}>
          <p>{rowData.address.street}</p>
          <p>{rowData.address.city}</p>
          <p>{rowData.address.state}</p>
          <p>{rowData.address.zip}</p>
        </div>
    }
  ]

  return (
    <div className={classes.root}>

      <MaterialTable
        title="Providers"
        style={{
          width: '80%'
        }}
        columns={columns}
        data={providers}
        actions={[
          rowData => ({
            icon: () =>
            <Switch
              checked={rowData.active}
              onChange={()=> changeStatus(rowData.active,rowData.uid)}
            />
          })
        ]}
        options={{
          exportButton: true
        }}
      />

      <BackDrop loading={loading} />
    </div>
  )
}

export default ProviderFormComponent
