import React, { useEffect, useState, useContext } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import MaterialTable from 'material-table';

import firebaseApp from '../../firebase';


const ChildrenTableComponent = ({family,uid}) => {

  const [ loading, setLoading ] = useState(false);
  const [ children, setChildren ] = useState([]);

  useEffect(() => {
    const childrenListener = firebaseApp.firestore().collection('users').doc(family.uid)
      .collection('families').doc(family.ref).collection('children')
      .onSnapshot(snapshot => {
        if(snapshot.size > 0) {
          let arr = [];
          snapshot.forEach(snap => {
            let tempData = snap.data();
            tempData.ref = snap.id;
            arr.push(tempData);
          })
          setChildren(arr);
          return;
        }
        setChildren([]);
      }, error => {
        console.log('ERROR',error);
      })

      return () => childrenListener();
  }, []);

  const removeChild = child => {
    setLoading(true);
    firebaseApp.firestore().collection('users').doc(family.uid)
      .collection('families').doc(family.ref).collection('children')
      .doc(child.ref).delete()
      .then(() => {
        setLoading(false);
        console.log('Successfully removed child.');
      })
      .catch(err => {
        console.log('ERROR',err);
        setLoading(false);
      })
  }

  const columns = [
    {
      title: 'Child',
      field: 'child_first',
      render: rowData => {
        return (
          <p>{ rowData.child_first + ' ' + rowData.child_last }</p>
        )
      }
    },
    {
      title: 'Age',
      render: rowData => {
        var ageDiff = new Date().getTime() - new Date(rowData.child_birth).getTime();
        var years = ((((ageDiff / 1000) / 60) / 60) / 24) / 365;
        return (
          <p>{years.toString().substr(0,3)}</p>
        )
      }
    }
  ]

  return (
    <div>
      <MaterialTable
        options={{
          search: false,
          pageSize: 3
        }}
        title="Child(ren)"
        columns={columns}
        data={children}

        actions={[
          {
            icon: () => <DeleteIcon color='secondary' />,
            tooltip: 'Delete Child',
            onClick: (event,rowData) => removeChild(rowData)
          }
        ]}

      />
    </div>
  )
}

export default ChildrenTableComponent
