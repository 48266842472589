import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Typography,
  Drawer,
  Select,
  MenuItem,
  Divider,
  InputLabel,
  FormHelperText,
  FormControl
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import BackDrop from '../shared/backdrop.component';
import * as firebase from 'firebase';
import firebaseApp from '../../firebase';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(3),
    marginTop: theme.spacing(10),
  },
  inputFields: {
    margin: theme.spacing(1)
  },
  ageField: {
    width: '50px',
    margin: theme.spacing(1)
  },
  selectField: {
    width: '100px',
    margin: theme.spacing(1)
  },
  submitBtn:  {
    margin: theme.spacing(2)
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  }
}))

const AddChildComponent = ({family,uid}) => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ addChild, setAddChild ] = useState(false);
  const [ sex, setSex ] = useState('');

  const handleSubmit = async(e) => {
    e.preventDefault();
    e.persist();
    setLoading(true);

    let data = {};
    data.child_first = e.target[0].value;
    data.child_last = e.target[1].value;
    data.child_birth = e.target[2].value;
    data.child_sex = e.target[3].value;

    firebaseApp.firestore().collection('users').doc(family.uid)
      .collection('families').doc(family.ref)
      .collection('children')
      .add(data)
      .then(() => {
        console.log('Successfully added child.');
        setLoading(false);
        e.target.reset();
      })
      .catch(err => {
        console.log('Error',err);
        setLoading(false);
        e.target.reset();
      })

  }

  return (
    <div>
      <form className={classes.form} onSubmit={handleSubmit}>

        <TextField
          type='text'
          label='First Name'
          name='child_first'
          placeholder="Child's First Name"
          required
          className={classes.inputFields}
        />

        <TextField
          type='text'
          label='Last Name'
          name='child_last'
          placeholder="Child's Last Name"
          required
          className={classes.inputFields}
        />

        <TextField
          type='date'
          label='Birth Date'
          name='child_birth'
          InputLabelProps={{
            shrink: true,
          }}
          required
          className={classes.inputFields}
        />

        <div className={classes.formControl}>
          <FormHelperText>Select Sex</FormHelperText>
          <Select
            name='child_sex'
            className={classes.selectField}
          >
            <MenuItem value='male'>Male</MenuItem>
            <MenuItem value='female'>Female</MenuItem>
          </Select>

        </div>

        <Button
          variant="contained"
          color="primary"
          type='submit'
          className={classes.submitBtn}
        >
          Add Child
        </Button>

      </form>

      <BackDrop loading={loading} />
    </div>
  )
}

export default AddChildComponent
