import firebaseApp from '../../firebase';

export const SignOut = async () => {
  firebaseApp.auth().signOut();
}

export const SignIn = async (email,password) => {
  firebaseApp.auth().signInWithEmailAndPassword(email, password)
    .catch(error => console.log('Error Signing in...',error));
}
