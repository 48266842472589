import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemText
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MaterialTable from 'material-table';

import MainContext from '../state/main.context';
import HeaderComponent from '../shared/header.component';
import firebaseApp from '../../firebase';

import ProviderFormComponent from './provider.form.component';
import ProvidersComponent from './providers.component';
import SignUpListComponent from './signups.component';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    // border: '1px solid blue'
  },
  menuButton: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    margin: theme.spacing(1),
    marginTop: theme.spacing(10)
  },
  list: {
    marginTop: theme.spacing(10)
  },
  drawer: {
    marginTop: theme.spacing(5),
  }
}))

const AdminHomeComponent = props => {
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ page, showPage ] = useState('Providers')
  const [ menuOpen, toggleMenu ] = useState(false);
  const menuOptions = [
    'Add Provider',
    'Providers',
    'Signups'
  ];

  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        {menuOptions.map((text) => (
          <div key={text}>
            <ListItem
              button
              onClick={() => showPage(text)}
              key={text}
              selected={page === text}
            >
              <ListItemText primary={text} />
            </ListItem>
            <Divider />
          </div>
        ))}


      </List>


    </div>
  );

  const toggleDrawer = () => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    toggleMenu(!menuOpen)
  };



  return (
    <div className={classes.root}>
      <HeaderComponent title='Admin' />

      <IconButton edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer()}
      >
        <MenuIcon />
      </IconButton>

      <Drawer open={menuOpen} onClose={toggleDrawer()} className={classes.drawer}>
        {sideList()}
      </Drawer>

      <div>

      {
        (page === 'Add Provider' && mainState.authUser) && (
          <ProviderFormComponent authUser={mainState.authUser} />
        )
      }

      {
        (page === 'Providers' && mainState.authUser) && (
          <ProvidersComponent authUser={mainState.authUser} />
        )
      }

      {
        (page === 'Signups' && mainState.authUser) && (
          <SignUpListComponent authUser={mainState.authUser} />
        )
      }

      </div>

    </div>
  )
}

export default AdminHomeComponent
