import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Typography,
  Drawer,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import MainContext from '../state/main.context';
import HeaderComponent from '../shared/header.component';
import firebaseApp from '../../firebase';
import MaterialTable from 'material-table';
import BackDrop from '../shared/backdrop.component';
import ErrorDialog from '../shared/error.dialog.component';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(3),
    marginTop: theme.spacing(12),
  },
  addBtn: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    margin: theme.spacing(2)
  },
  inputFields: {
    margin: theme.spacing(1)
  },
  expenseBtn:  {
    margin: theme.spacing(2)
  },
  table: {
    width: '50vw',
    marginTop: '5vh'
  }
}))

const ExpensesComponent = props => {
  let { uid } = props;
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  let { authUser } = mainState;
  const classes = useStyles();
  const [ loading, setLoading ] = useState(true);
  const [ expenses, setExpenses ] = useState([]);
  const [ showForm, toggleForm ] = useState(false);
  const [ expenseType, setExpenseType ] = useState(null);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ showError, setShowError ] = useState(false);
  const [ expenseBreakdown, setExpenseBreakdown ] = useState(null);

  useEffect(() => {
    const expenseListener = firebaseApp.firestore().collection('users').doc(uid)
      .collection('expenses').onSnapshot(snapshot => {

        if(snapshot.size > 0) {
          let tempArr = [];
          snapshot.forEach(snap => {
            let tempData = snap.data();
            tempData.ref = snap.id;
            tempArr.push(tempData)
          })
          setExpenses(tempArr);
          setLoading(false);
          breakdownExpenses(tempArr);
          return;
        }
        setExpenses([]);
        setLoading(false);
      }, error => {
        console.log('ERROR',error);
        setLoading(false);
      })

      return () => expenseListener();
  },[]);

  const breakdownExpenses = expenseData => {
    console.dir(expenseData);

    const obj = expenseData.reduce((accummulator, currentValue) => {

      if(accummulator.hasOwnProperty(currentValue.year)) {

          //  DIRECT BREAKDOWN
          if(currentValue.direct) {

            accummulator[currentValue.year].direct.total = (Number(accummulator[currentValue.year].direct.total) + Number(currentValue.amount)).toFixed(2)

            if(currentValue.hasOwnProperty('mileage')) {

              accummulator[currentValue.year].direct.mileage =  (Number(accummulator[currentValue.year].direct.mileage) + Number(currentValue.mileage))

            }

          }


          //  INDIRECT BREAKDOWN
          if(currentValue.indirect) {

            accummulator[currentValue.year].indirect.total = (Number(accummulator[currentValue.year].indirect.total) + Number(currentValue.amount)).toFixed(2)

            if(currentValue.hasOwnProperty('mileage')) {

              accummulator[currentValue.year].indirect.mileage =  (Number(accummulator[currentValue.year].indirect.mileage) + Number(currentValue.mileage))

              if(accummulator[currentValue.year].indirect.mileage > 0) {
                console.log(currentValue);
              }

            }

          }

      } else {

          accummulator[currentValue.year] = {
            indirect: {
              total: currentValue.indirect ? currentValue.amount : 0.00,
              mileage: (currentValue.indirect && currentValue.hasOwnProperty('mileage'))
                        ? Number(currentValue.mileage) : 0
            },
            direct: {
              total: currentValue.direct ? currentValue.amount : 0.00,
              mileage: (currentValue.direct && currentValue.hasOwnProperty('mileage'))
                        ? Number(currentValue.mileage) : 0
            }
          }

      }

      return accummulator;
    },{});
    console.log(obj);
    setExpenseBreakdown(obj);
  }

  const columns = [
    {
      title: 'Date',
      field: 'date',
      defaultSort: 'desc'
    },
    {
      title: 'Name',
      field: 'name'
    },
    {
      title: 'Amount',
      field: 'amount',
      render: rowData => <p>${rowData.amount}</p>
    },
    {
      title: 'Note',
      field: 'notes'
    },
    {
      title: 'Expense Type',
      render: rowData => <p>{ rowData.direct ? 'Direct' : 'Indirect' }</p>
    }
  ]

  const initShowError = message => {
    setErrorMessage(message);
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    },2000)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();

    if(!expenseType) {
      initShowError('Please select either Direct or Indirect Expense.');
      return;
    }

    setLoading(true);

    let data = {};
    data.date = e.target[0].value;
    data.name = e.target[1].value;
    data.amount = e.target[2].value;
    data.mileage = e.target[3].value || 0;
    data.notes = e.target[4].value;
    data.year = new Date(data.date).getUTCFullYear();
    data.direct = expenseType === 'direct' ? true : false;
    data.indirect = expenseType === 'indirect' ? true : false;

    // console.dir(data);
    //
    // setLoading(false);
    //
    // return;

    firebaseApp.firestore().collection('users').doc(uid)
      .collection('expenses').add(data)
      .then(doc => {
        e.target.reset();
        setLoading(false);
      })
      .catch(err => {
        console.log('ERROR',err);
        e.target.reset();
        setLoading(false);
      })

  }

  const initDeleteEntry = (rowData) => {
    setLoading(true);

    firebaseApp.firestore().collection('users').doc(uid)
      .collection('expenses').doc(rowData.ref)
      .delete().then(() => {
        setLoading(false);
      })
      .catch(err => {
        console.log('ERROR',err);
        setLoading(false);
      })
  }

  const ExpenseTable = props => (
    <TableContainer component={Paper} className={classes.table}>
      <Table >
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Direct</TableCell>
            <TableCell>Direct Mileage</TableCell>
            <TableCell>Indirect</TableCell>
            <TableCell>Indirect Mileage</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>

          {
            Object.keys(expenseBreakdown).map(each =>
              <TableRow key={each}>
                <TableCell>{each}</TableCell>
                <TableCell>${Number(expenseBreakdown[each].direct.total).toFixed(2)}</TableCell>
                <TableCell>{Number(expenseBreakdown[each].direct.mileage)}</TableCell>
                <TableCell>${Number(expenseBreakdown[each].indirect.total).toFixed(2)}</TableCell>
                <TableCell>{Number(expenseBreakdown[each].indirect.mileage)}</TableCell>
              </TableRow>
            )
          }

        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <div className={classes.root}>

      <Button
        variant='contained'
        color='secondary'
        className={classes.addBtn}
        onClick={() => toggleForm(!showForm)}
      >
        Add Expense
      </Button>

      {
        expenses && (
          <MaterialTable
            width='100%'
            title='Expenses'
            columns={columns}
            data={expenses}
            actions={[
              {
                icon: () => <DeleteIcon color='secondary' />,
                tooltip: 'Delete Entry',
                onClick: (event,rowData) => initDeleteEntry(rowData)
              }
            ]}
            options={{
              exportButton: true,
              pageSize: 50
            }}
          />
        )
      }

      {
        expenseBreakdown && (
          <ExpenseTable />
        )
      }

      <Drawer anchor='right' open={showForm}>

        <form
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <Typography style={{ textAlign: 'center' }}>Add Expense</Typography>
          <TextField
            className={classes.inputFields}
            type='date'
            label='Date'
            name='date'
            InputLabelProps={{
              shrink: true,
            }}
            required
            helperText="Expense Tax Year is determined by this date."
          />

          <TextField
            className={classes.inputFields}
            type='text'
            label='Name'
            name='name'
            placeholder='Enter Name'
            required
          />

          <TextField
            className={classes.inputFields}
            inputProps={{
              step: 0.01
            }}
            id="standard-with-placeholder"
            type='number'
            label='Amount'
            name='amount'
            placeholder='Enter Amount'
            required
          />

          <TextField
            className={classes.inputFields}
            id="standard-with-placeholder"
            type='number'
            inputProps={{
              step: 0.01
            }}
            label='Mileage'
            name='mileage'
            placeholder='Enter Mileage (optional)'
          />

          <TextField
            className={classes.inputFields}
            type='text'
            multiline
            rows='4'
            label='Note'
            name='notes'
            placeholder='Enter Note'
          />

          <RadioGroup name='expense-type'
            value={expenseType}
            onChange={(e) => setExpenseType(e.target.value)}
          >
            <FormControlLabel
              value="direct"
              control={<Radio />}
              label="Direct Expense"
            />

            <FormControlLabel
              value="indirect"
              control={<Radio />}
              label="Indirect Expense"
            />
          </RadioGroup>

          <Button
            variant="contained"
            color="primary"
            type='submit'
            className={classes.expenseBtn}
          >
            Enter Expense
          </Button>

          <Button
            variant="contained"
            color="secondary"
            className={classes.expenseBtn}
            onClick={() => toggleForm(!showForm)}
          >
            Close
          </Button>

        </form>

      </Drawer>

      <BackDrop loading={loading} />
    </div>
  )
}

export default ExpensesComponent
