import React, { useEffect, useContext, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import MainContext from '../state/main.context';
import { SignOut } from '../utilities/firebase.actions';

const useStyles = makeStyles( theme => ({
  mainTitle: {
    marginRight: theme.spacing(2),
    cursor: 'pointer'
  },
  loginBtn: {
    flexGrow: 1,
    justifyContent: 'flex-end'
  }
}))


const CustomHeader = ({ title }) => {
  const history = useHistory();
  const classes = useStyles();
  const context = useContext(MainContext);

  const [ showBtn, setShowBtn ] = useState(true);

  let { mainState, mainDispatch } = context;

  useEffect(() => {

    switch(title) {
      case 'Confirm Account | Change Password':
      case 'Sign Up':
      case 'Login':
      case 'Sign-up':
        setShowBtn(false);
        break;

      default:
        setShowBtn(true);
    }

  },[])

  const handleClick = () => {
    if(!mainState.authUser) {
      history.push('/login');
      return;
    }
    mainDispatch({ type: 'reset' });
    //  LOGOUT METHOD
    history.push('/');
    SignOut();
  }

    return (
      <AppBar position='fixed' color={title === 'Home' ? 'secondary' : 'primary'}>
        <Toolbar>
          <Typography
            variant='h6'
            noWrap
            className={classes.mainTitle}
            onClick={() => history.push('/')}
          >
          Munchkin Notes  |
          </Typography>

          <Typography
          >
          { title }
          </Typography>
          {
            showBtn ?

              <Button
                color='inherit'
                className={classes.loginBtn}
                onClick={handleClick}
              >
                {mainState.authUser && title !== 'Home' ? 'Logout' : 'Login'}
              </Button>

            : null

          }

        </Toolbar>
      </AppBar>
    )
}

export default CustomHeader;
