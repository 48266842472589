import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Typography,
  Drawer,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import MainContext from '../state/main.context';
import HeaderComponent from '../shared/header.component';
import firebaseApp from '../../firebase';
import MaterialTable from 'material-table';
import BackDrop from '../shared/backdrop.component';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(3),
    marginTop: theme.spacing(12),
  },
  addBtn: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    margin: theme.spacing(2)
  },
  inputFields: {
    margin: theme.spacing(1)
  },
  expenseBtn:  {
    margin: theme.spacing(2)
  },
  table: {
    width: '50vw',
    marginTop: '5vh'
  }
}))

const IncomeComponent = props => {
  let { uid } = props;
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  let { authUser } = mainState;
  const classes = useStyles();
  const [ loading, setLoading ] = useState(true);
  const [ income, setIncome ] = useState([]);
  const [ showForm, toggleForm ] = useState(false);
  const [ families, setFamilies ] = useState([]);
  const [ familySelected, setFamily ] = useState(null);
  const [ incomeBreakdown, setIncomeBreakdown ] = useState(null);

  useEffect(() => {
    const incomeListener = firebaseApp.firestore().collection('users').doc(uid)
      .collection('income').onSnapshot(snapshot => {

        if(snapshot.size > 0) {
          let tempArr = [];
          snapshot.forEach(snap => {
            let tempData = snap.data();
            tempData.ref = snap.id;
            tempData.family = tempData.family.family;
            tempArr.push(tempData)
          })
          setIncome([...tempArr]);
          breakdownIncome(tempArr);
          setLoading(false);
          return;
        }
        setIncome([]);
        setLoading(false);
      }, error => {
        console.log('ERROR',error);
        setLoading(false);
      })

      const familiesListener = firebaseApp.firestore().collection('users').doc(uid)
        .collection('families').onSnapshot(snapshot => {

          if(snapshot.size > 0) {
            let tempArr = [];
            snapshot.forEach(snap => {
              let tempData = snap.data();
              tempData.ref = snap.id;
              tempArr.push(tempData)
            })
            setFamilies([...tempArr]);
            // console.dir(tempArr);
            setLoading(false);
            return;
          }
          setFamilies([]);
          setLoading(false);
        }, error => {
          console.log('ERROR',error);
          setLoading(false);
        })

      return () => {
        incomeListener();
        familiesListener();
      }

  },[])

  const breakdownIncome = incomeData => {
    const obj = incomeData.reduce((accummulator, currentValue) => {

      if(accummulator.hasOwnProperty(currentValue.year)) {

          accummulator[currentValue.year] = (Number(accummulator[currentValue.year]) +
            Number(currentValue.amount)).toFixed(2);

      } else {

        accummulator[currentValue.year] =  Number(currentValue.amount).toFixed(2);

      }

      return accummulator;
    },{});

    setIncomeBreakdown(obj);
  }

  const IncomeTable = props => (
    <TableContainer component={Paper} className={classes.table}>
      <Table >
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>

          {
            Object.keys(incomeBreakdown).map(each =>
              <TableRow key={each}>
                <TableCell>{each}</TableCell>
                <TableCell>${Number(incomeBreakdown[each]).toFixed(2)}</TableCell>
              </TableRow>
            )
          }

        </TableBody>
      </Table>
    </TableContainer>
  )

  const columns = [
    {
      title: 'Date',
      field: 'date',
      defaultSort: 'desc'
    },
    {
      title: 'Family',
      field: 'family',
    },
    {
      title: 'Check Number',
      field: 'check_number'
    },
    {
      title: 'Amount',
      field: 'amount',
      render: rowData => <p>${Number(rowData.amount).toFixed(2)}</p>
    },
    {
      title: 'Note',
      field: 'notes'
    }
  ]

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    setLoading(true);

    let data = {};
    data.date = e.target[0].value;

    data.family = {};
    data.family.ref = e.target[1].value;

    families.some(family => {
      if(family.ref === data.family.ref) {
        data.family.family = family.parent1_last + ', ' + family.parent1_first;
        return true;
      }
      return false;
    })


    data.check_number = e.target[2].value;
    data.amount = e.target[3].value;
    data.notes = e.target[4].value;
    data.year = new Date(data.date).getUTCFullYear();

    firebaseApp.firestore().collection('users').doc(uid)
      .collection('income').add(data)
      .then(doc => {
        e.target.reset();
        setFamily(null);
        setLoading(false);
      })
      .catch(err => {
        console.log('ERROR',err);
        e.target.reset();
        setFamily(null);
        setLoading(false);
      })

  }

  const initDeleteEntry = (rowData) => {
    setLoading(true);

    firebaseApp.firestore().collection('users').doc(uid)
      .collection('income').doc(rowData.ref)
      .delete().then(() => {
        setLoading(false);
      })
      .catch(err => {
        console.log('ERROR',err);
        setLoading(false);
      })
  }

  const handleSelect = e => {
    setFamily(e.target.value);
  }

  const IncomeForm = () => (
    <form
      onSubmit={handleSubmit}
      className={classes.form}
    >
      <Typography style={{ textAlign: 'center' }}>Add Income</Typography>

      <TextField
        type='date'
        label='Date'
        name='date'
        InputLabelProps={{
          shrink: true,
        }}
        className={classes.inputFields}
        helperText="Income Tax Year is determined by this date."
      />

      <FormControl
        className={classes.inputFields}
      >
        <InputLabel>Family</InputLabel>
        <Select
          name='family'
          placeholder='Enter Family'
          className={classes.inputFields}
          // onChange={handleSelect}
        >
          {
            families.map(family => {
              return <MenuItem
                        key={family.ref}
                        value={family.ref}
                     >{family.parent1_last + ', ' + family.parent1_first}</MenuItem>
            })
          }
        </Select>
      </FormControl>

      <TextField
        type='text'
        label='Check Number'
        name='check_number'
        placeholder='Enter Check Number'
        className={classes.inputFields}
      />

      <TextField
        id="standard-with-placeholder"
        type='number'
        label='Amount'
        name='amount'
        inputProps={{
          step: 0.01
        }}
        placeholder='Enter Amount'
        className={classes.inputFields}
      />

      <TextField
        type='text'
        multiline
        rowsMax='4'
        label='Notes'
        name='notes'
        placeholder='Enter Notes'
        className={classes.inputFields}
      />

      <Button
        variant="contained"
        color="primary"
        type='submit'
        className={classes.submitBtn}
      >
        Record Income
      </Button>

      <Button
        variant="contained"
        color="secondary"
        className={classes.expenseBtn}
        onClick={() => toggleForm(!showForm)}
      >
        Close
      </Button>

    </form>
  )

  return (
    <div className={classes.root}>

      <Button
        variant='contained'
        color='secondary'
        className={classes.addBtn}
        onClick={() => toggleForm(!showForm)}
      >
        Add Income
      </Button>


      <MaterialTable
        width='100%'
        title='Income'
        columns={columns}
        data={income}
        actions={[
          {
            icon: () => <DeleteIcon color='secondary' />,
            tooltip: 'Delete Entry',
            onClick: (event,rowData) => initDeleteEntry(rowData)
          }
        ]}
        options={{
          exportButton: true,
          pageSize: 50
        }}
      />

      {
        incomeBreakdown && (
          <IncomeTable />
        )
      }

      <Drawer anchor='right' open={showForm}>

        <IncomeForm famSelected={familySelected}/>

      </Drawer>

      <BackDrop loading={loading} />
    </div>
  )
}

export default IncomeComponent
