import firebase from 'firebase';

const apiKey = process.env.REACT_APP_API_KEY
const authDomain = process.env.REACT_APP_AUTH_DOMAIN
const databaseURL = process.env.REACT_APP_DATABASE_URL
const projectId = process.env.REACT_APP_PROJECT_ID
const appId = process.env.REACT_APP_APP_ID
const measurementId = process.env.REACT_APP_MEASUREMENT_ID

var config = {
  apiKey: apiKey,
  authDomain: authDomain,
  databaseURL: databaseURL,
  projectId: projectId,
  appId: appId,
  measurementId: measurementId
};

const firebaseApp = firebase.initializeApp(config);


export default firebaseApp;
