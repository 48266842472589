export const initialState = {
  authUser: null,
  userType: null,
  families: [],
  providerInfo: null
}

export const mainReducer = (state,action) => {
  switch(action.type) {
    case 'setAuthUser':
      return {
        ...state,
        authUser: action.payload
      }

    case 'setUserType':
      return {
        ...state,
        userType: action.payload
      }

    case 'setProviderInfo':
      return {
        ...state,
        providerInfo: action.payload
      }

    case 'setFamilies':
      return {
        ...state,
        families: action.payload
      }

    case 'reset':
      return {
        ...initialState
      }
  }
}
