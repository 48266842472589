import React, { useState } from 'react';
import HeaderComponent from '../shared/header.component';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import BackDrop from '../shared/backdrop.component';
import ConfirmDialog from '../shared/confirm.dialog.component';
import firebaseApp from '../../firebase'
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingBottom: '10vh',
      paddingTop: '8vh',
      // backgroundColor: '#b5a33f'
  },
  paper1: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5)
  },
  paper: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5)
  },
  paperHow: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contact: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontSize: '8vw'
  },
  para: {
    fontSize: '3vw'
  },
  howTitle: {
    alignSelf: 'flex-start'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    alignSelf: 'center'
  },
  inputFields: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1)
  },
  footer: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footerLinks: {
    textDecoration: 'none',
    color: 'white',
    margin: theme.spacing(1)
  },
  rights: {
    margin: theme.spacing(1)
  }
}))

const HomePage = props => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ confirmMessage, setConfirmMessage ] = useState('');
  const [ purpose, setPurpose ] = useState("");
  const [ captcha_val, setCaptcha ] = useState(null);
  const RECAPTCHA_SERVER_KEY = process.env.REACT_APP_RECAPTCHA_SERVER_KEY;
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();

    let captcha_options = {
      method: 'Post',
      body: JSON.stringify({
        client_response: captcha_val,
        server_key: RECAPTCHA_SERVER_KEY
      })
    }
    let captcha_url = "https://us-central1-munchkin-notes.cloudfunctions.net/verifyReCaptcha";

    setLoading(true);

    let verify_response = await fetch(captcha_url,captcha_options);

    let json = await verify_response.json();

    if(!json.success) {
      console.log('You failed the captcha.');
      setLoading(false);
      return;
    }

    let obj = {};
    obj.user = e.target[0].value;
    obj.email = e.target[1].value;
    obj.phone = e.target[2].value || "";
    obj.purpose = purpose;
    obj.message = e.target[4].value || "";

    let options = {
      method: 'Post',
      body: JSON.stringify(obj)
    }

    let url = "https://us-central1-munchkin-notes.cloudfunctions.net/sendContactMessage";

    try {

      let initSend = await fetch(url,options);
      if(initSend.ok) {
        setConfirmMessage("Information received.  We'll be in touch soon!");
        setLoading(false);
        setShowConfirm(true);
        e.target.reset();
        return;
      }

    } catch (e) {
      e.target.reset();

      console.log('ERROR',e);

      setLoading(false);

    }

  }

  const handleCaptcha = val => setCaptcha(val);

  return (
    <div className={classes.root}>
      <HeaderComponent title="Home" />

      <Paper className={classes.paper1} elevation={5}>
        <Typography
          variant="h2"
          style={{ textAlign: 'center' }}
          color='secondary'
        >A service for in-home daycare providers...</Typography>
      </Paper>

      <Paper className={classes.paper} elevation={5}>
        <Typography variant="h2">Manage Income & Expenses</Typography>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Typography variant="h5">
            Easily record all income and expenses.
          </Typography>
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Typography variant="h5">
            Categorize direct and indirect expenses.
          </Typography>
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Typography variant="h5">
            Track all yearly income and expense totals in real-time.
          </Typography>
        </ListItem>

      </Paper>

      <Paper className={classes.paper} elevation={5}>
        <Typography variant="h2">Simplify Your Tax Workflow</Typography>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Typography variant="h5">
            Print or email automatically generated payment records for your clients.
          </Typography>
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Typography variant="h5">
            Access real-time expenditures by year categorized as an indirect or direct expense.
          </Typography>
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Typography variant="h5">
            Track all income and expense totals in real-time.
          </Typography>
        </ListItem>

      </Paper>

      <Paper className={classes.paper} elevation={5}>

        <Typography variant="h2">Manage Client Information</Typography>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Typography variant="h5">
            Keep accurate records of all of your clients.
          </Typography>
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Typography variant="h5">
            Easily access client information.
          </Typography>
        </ListItem>

      </Paper>

      <Paper className={classes.paperHow} elevation={5}>

        <Typography variant="h2" className={classes.howTitle}>How we got started...</Typography>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>

          <Typography variant='h5'>
            My wife has been a daycare provider for ten years.  She is amazing with children and as a former accountant, she managed income and expenses very well at the onstart.  Overtime, however, the curriculum and meal-planning took precedence and the tracking of income and expenses took a toll.
          </Typography>

        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>

          <Typography variant='h5'>
            For a couple of years, we felt that toll build up.  Every time we had to file for taxes, it was a constant struggle and we were 100% certain we were losing money just because our information was inaccurate.
          </Typography>

        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>

          <Typography variant='h5'>
            I decided to build my wife a basic management tool that was intended to help us during tax season.  While it did it's intended purpose, we got ambitious and continued to add features to make her life a little bit easier.
          </Typography>

        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>

          <Typography variant='h5'>
            We are at a point where we believe this product will benefit daycare providers like my wife.  We are confident that it will save people a lot of time and frustrations.
          </Typography>

        </ListItem>

      </Paper>

      <Paper className={classes.contact} elevation={5}>

        <Typography variant="h3" className={classes.howTitle}>Let us know what you need!</Typography>

        <form
          onSubmit={handleSubmit}
          className={classes.form}
        >

          <TextField
            type='text'
            label="Name"
            className={classes.inputFields}
            required
          />
          <TextField
            type='email'
            label="Email Address"
            className={classes.inputFields}
            required
          />
          <TextField
            type='text'
            label="Phone Number (optional)"
            className={classes.inputFields}
          />

          <FormControl className={classes.formControl}>
            <InputLabel>Purpose</InputLabel>
            <Select
              value={purpose}
              onChange={e => setPurpose(e.target.value)}
            >
              <MenuItem value="Learn More">Learn More</MenuItem>
              <MenuItem value="Contact Me">Contact Me</MenuItem>
              <MenuItem value="Concern">Concern</MenuItem>
              <MenuItem value="Suggestion">Suggestion</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>

          <TextField
            type='text'
            multiline
            variant="outlined"
            rows={4}
            label="Optional Message"
            className={classes.inputFields}
          />

          <ReCAPTCHA
            sitekey={ RECAPTCHA_SITE_KEY }
            onChange={handleCaptcha}
          />

          <Button
            variant='contained'
            color='primary'
            type='submit'
            className={classes.inputFields}
          >
            Send
          </Button>
        </form>

      </Paper>

      <Paper className={classes.footer} elevation={5}>
        <a className={classes.footerLinks}
          href="https://docs.google.com/document/d/1yDNF1QPe9Wf2rVDjhAoAzJZoLNS6nYUed-nkmo0HcD0/preview" target="_blank">
          <Typography>Privacy Policy</Typography>
        </a>

        <a className={classes.footerLinks}
          href="https://docs.google.com/document/d/11ZFuK-8-RlajZfD64uG002w0W63VI_W36cXgCUkaooA/preview" target="_blank">
          <Typography>Terms and Conditions</Typography>
        </a>

        <Typography className={classes.rights}>All Rights Reserved to MunchkinNotes, 2020 { new Date().getUTCFullYear() > 2020 ? " - " + new Date().getUTCFullYear() : null}</Typography>
      </Paper>

      <ConfirmDialog message={confirmMessage} toOpen={showConfirm} />
      <BackDrop loading={loading} />
    </div>
  )
}

export default HomePage
