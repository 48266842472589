import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

import GlobalState from './app/state/global.state';
import HomePage from './app/static/home.page';
import LogInComponent from './app/shared/login.component';
import DefaultHomeComponent from './app/default_user/default.home.component';
import AdminHomeComponent from './app/admin/admin.home.component';
import ChangePasswordComponent from './app/shared/change_password.component';
import CompleteRegistration from './app/shared/complete_registration.component';
import SubscriptionComponent from './app/shared/subscription.component';
import ContactUsComponent from './app/static/contact_us.page';
import DemoAndPricing from './app/static/demo_pricing.page';
import SignupFormComponent from './app/static/signup.form.component';

const App = props => (
  <GlobalState>
    <Router>
      <Switch>
        <Route path='/' exact component={HomePage} />
        <Route path='/login' component={LogInComponent} />
        <Route path='/default-user' component={DefaultHomeComponent} />
        <Route path='/super-admin' component={AdminHomeComponent} />
        <Route path='/change-password/:uid/:email' component={ChangePasswordComponent} />
        <Route path='/complete-registration/:email/:uid' component={CompleteRegistration} />
        <Route path='/subscription' component={SubscriptionComponent} />
        <Route path='/contact-us' component={ContactUsComponent} />
        <Route path='/demo-pricing' component={DemoAndPricing} />
        <Route path='/signup' component={SignupFormComponent} />
      </Switch>
    </Router>
  </GlobalState>
)

export default App;
