import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Drawer,
  List,
  Divider,
  TextField,
  Button,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HeaderComponent from '../shared/header.component';
import MainContext from '../state/main.context';
import firebaseApp from '../../firebase';
import ErrorDialog from '../shared/error.dialog.component';
import ConfirmDialog from '../shared/confirm.dialog.component';
import BackDrop from '../shared/backdrop.component';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3),
    marginTop: theme.spacing(15),
  },
  addBtn: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    border: '1px solid lightgrey',
    borderRadius: '5px'
  },
  inputFields: {
    margin: theme.spacing(1)
  },
  submitBtn:  {
    margin: theme.spacing(2)
  }
}))

const SignupFormComponent = props => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ stateSelected, selectState ] = useState(null);
  const [ showError, setShowError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ confirmMessage, setConfirmMessage ] = useState('');
  const [ showConfirm, setShowConfirm ] = useState(false);
  const states = [
    'AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'
  ];


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    if(!stateSelected) {
      initShowError('Please select a state.');
      return;
    }

    if(e.target[12].value) {
      return;
    }

    let obj = {};
    obj.first_name = (e.target[0].value).trim();
    obj.last_name = (e.target[1].value).trim();
    obj.email = (e.target[2].value).trim();
    obj.phone = (e.target[3].value).trim();
    obj.company = (e.target[4].value).trim();
    obj.address = {
      street: (e.target[5].value).trim(),
      city: (e.target[6].value).trim(),
      zip: (e.target[7].value).trim(),
      state: (stateSelected).trim() || (e.target[8].value).trim()
    }

    let options = {
      method: 'Post',
      body: JSON.stringify(obj)
    }
    let url = "https://us-central1-munchkin-notes.cloudfunctions.net/initSignUp";
    // let url = "http://localhost:5000/munchkin-notes/us-central1/initSignUp";

    setLoading(true);

    try {

      let initSignUp = await fetch(url,options);
      let textRes = await initSignUp.text();
      setLoading(false);
      e.target.reset();
      if(initSignUp.ok) {
        initShowConfirm("Successfully submitted information.  You will receive an email shortly.");
        return;
      }

      initShowError(textRes);

    } catch (e) {
      console.log('ERROR',e);
      setLoading(false);
      initShowError('THERE WAS AN ISSUE SIGNING UP.');
    }

  }

  const initShowError = message =>  {
    setErrorMessage(message);
    setShowError(true);
    setTimeout(() => setShowError(false),2000);
  }

  const initShowConfirm = message =>  {
    setConfirmMessage(message);
    setShowConfirm(true);
    setTimeout(() => setShowConfirm(false),2000);
  }

  return (
    <div className={classes.root}>
      <HeaderComponent title='Sign-up' />

      <form onSubmit={handleSubmit}
        className={classes.form}
      >
        <TextField
          type='text'
          label='First Name'
          name='first_name'
          className={classes.inputFields}
          required
        />

        <TextField
          type='text'
          label='Last Name'
          name='last_name'
          className={classes.inputFields}
          required
        />

        <TextField
          type='email'
          label='Email'
          name='email'
          className={classes.inputFields}
          required
        />

        <TextField
          type='text'
          label='Phone Number'
          name='phone'
          className={classes.inputFields}
          required
        />

        <TextField
          type='text'
          label='Company Name'
          name='company'
          className={classes.inputFields}
          required
        />

        <TextField
          multiline
          rows={3}
          type='text'
          label='Street Address'
          name='street_addr'
          className={classes.inputFields}
          required
        />

        <TextField
          type='text'
          label='City'
          name='city_addr'
          className={classes.inputFields}
          required
        />

        <TextField
          type='text'
          label='Zip'
          name='zip_addr'
          className={classes.inputFields}
          required
        />

        <Autocomplete
          required
          options={states}
          getOptionsLabel={option => option}
          className={classes.inputFields}
          renderInput={(params) => <TextField { ...params } label="State" variant='outlined' />}
          onChange={(e,val) => {
            console.log(val);
            selectState(val)
          }}
        />

        <input type='text' defaultValue='' id='munchkin' hidden />

        <Button
          variant="contained"
          color="primary"
          type='submit'
          className={classes.submitBtn}
        >
          Sign Up
        </Button>
      </form>

      <ErrorDialog message={errorMessage} toOpen={showError} />
      <ConfirmDialog message={confirmMessage} toOpen={showConfirm} />
      <BackDrop loading={loading} />
    </div>
  )
}

export default SignupFormComponent
