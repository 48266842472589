import React, { useEffect, useState, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Drawer,
  List,
  Divider,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem
} from '@material-ui/core';
import HeaderComponent from '../shared/header.component';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '10vh',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  note: {
    width: '60%',
    margin: theme.spacing(2)
  },
  paymentDiv: {
    width: '50%',
    margin: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    width: "40%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  centeredText: {
    textAlign: 'center'
  }
}))


const SubscriptionComponent = props => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ paypal, setPaypal ] = useState(null);
  const [ paidFor, setPaidFor ] = useState(false);
  const [ loaded, setLoaded ] = useState(false);
  const [ numChildren, setNumChildren ] = useState("");

  let paypalRef1 = useRef();
  let paypalRef2 = useRef();
  let paypalRef3 = useRef();

  useEffect(() => {

    const script = document.createElement('script');
    script.src = "https://www.paypal.com/sdk/js?client-id=Aeooi7nHFtvNFyhedNLpBZCWR1WeyJgUfz22UIwRp3VR9zXk8B-aJ69ItwVOPqLC2B1u4wHUSu0rc728&vault=true&components=buttons";
    script['data-sdk-integration-source'] = 'button-factory';
    script.addEventListener('load',() => setLoaded(true));
    document.body.appendChild(script);

    if(loaded) {
      setTimeout(() => {
        //  SUBSCRIBERS
        window.paypal.Buttons({
            style: {
              shape: 'rect',
              color: 'gold',
              layout: 'vertical',
              label: 'subscribe',
            },
            createSubscription: (data, actions) => {
                return actions.subscription.create({
                  'plan_id': 'P-3XT08527PS791593NL36Q3LY'
                })
            },
            onApprove: async (data, actions ) => {
              setPaidFor(true);
            }
          })
          .render(paypalRef1);

          window.paypal.Buttons({
              style: {
                shape: 'rect',
                color: 'gold',
                layout: 'vertical',
                label: 'subscribe',
              },
              createSubscription: (data, actions) => {
                  return actions.subscription.create({
                    'plan_id': 'P-39618030C5007521GL36Q4EQ'
                  })
              },
              onApprove: async (data, actions ) => {
                setPaidFor(true);
              }
            })
            .render(paypalRef2);

            window.paypal.Buttons({
                style: {
                  shape: 'rect',
                  color: 'gold',
                  layout: 'vertical',
                  label: 'subscribe',
                },
                createSubscription: (data, actions) => {
                    return actions.subscription.create({
                      'plan_id': 'P-10F15789RM7076605L36Q45Y'
                    })
                },
                onApprove: async (data, actions ) => {
                  setPaidFor(true);
                }
              })
              .render(paypalRef3);

      },200)
    }

  });

  const handleChange = e => {
    let val = e.target.value;
    setNumChildren(val);
    console.log(val);

    switch(val) {
      case "1-8 Child(ren)":

        document.getElementById('level1').style.display = 'block';
        document.getElementById('level2').style.display = 'none';
        document.getElementById('level3').style.display = 'none';
        break;

      case "9-16 Children":

        document.getElementById('level1').style.display = 'none';
        document.getElementById('level2').style.display = 'block';
        document.getElementById('level3').style.display = 'none';
        break;

      case "17+ Children":

        document.getElementById('level1').style.display = 'none';
        document.getElementById('level2').style.display = 'none';
        document.getElementById('level3').style.display = 'block';
        break;

      default:
        return;
    }
  }

  return (
    <div className={classes.container}>
      <HeaderComponent title='Subscription' />

      <Typography className={classes.note}>
        <b>Please Note:</b>  In an effort to streamline and, more importantly, secure payment processing, please ensure that you have created a PayPal account.  <span style={{ fontStyle: 'italic'}}>You do have the option to pay using a Debit or Credit Card at the bottom</span> of the options but it is highly recommended to use the PayPal Subscribe or Paypal Credit Subscribe.  This will make it easier for you and MunchkinNotes to process payments as well as avoid any service interruptions.
      </Typography>

      <Typography className={classes.note}>
        Here is a <a href="https://www.youtube.com/watch?v=wlAo43FEj-I" target="_blank">video on how to setup a Paypal Account.</a>
      </Typography>

      <Typography className={classes.note}>
        Here is the <a href="https://www.paypal.com/us/webapps/mpp/how-paypal-works/how-to-setup-account" target="_blank">website</a> detailing more information about Paypal accounts.
      </Typography>

      <Typography className={classes.note}>
        Please make sure that you use the same email that you used to register your account with MunchkinNotes.
      </Typography>

      <Typography className={classes.note}>
        When you are ready, select from the dropdown to begin.
      </Typography>


      <FormControl className={classes.formControl}>
        <InputLabel shrink>
          Number of children served (anticipated or current)
        </InputLabel>
        <Select
          displayEmpty
          value={numChildren}
          onChange={handleChange}
          className={classes.selectEmpty}
        >
          <MenuItem value="">
            <em>Select Option</em>
          </MenuItem>
          <MenuItem value="1-8 Child(ren)">1-8 Child(ren)</MenuItem>
          <MenuItem value="9-16 Children">9-16 Children</MenuItem>
          <MenuItem value="17+ Children">17+ Children</MenuItem>
        </Select>
      </FormControl>

      {
        paidFor ? (
          <div>
            <h1> Congrats, your subscription payment has been received.</h1>
          </div>
        ) : (
          <div>
              {
                numChildren === "1-8 Child(ren)" && (
                  <div className={classes.centeredText}>
                    <h2>Level 1 Subscriptions $9.99/mo</h2>
                    <p>2 Months Free Trial</p>
                  </div>
                )
              }

              {
                numChildren === "9-16 Children" && (
                  <div className={classes.centeredText}>
                  <h2>Level 2 Subscription $14.99/mo</h2>
                  <p>2 Months Free Trial</p>
                  </div>
                )
              }

              {
                numChildren === "17+ Children" && (
                  <div className={classes.centeredText}>
                  <h2>Level 3 Subscription $24.99/mo</h2>
                  <p>2 Months Free Trial</p>
                  </div>
                )
              }

            <div
              style={{ display: 'none'}}
              id='level1'
              ref={v => (paypalRef1 = v )}
            />
            <div
              style={{ display: 'none'}}
              id='level2'
              ref={v => (paypalRef2 = v )}
            />
            <div
              style={{ display: 'none'}}
              id='level3'
              ref={v => (paypalRef3 = v )}
            />
          </div>
        )
      }

    </div>
  )
}

export default SubscriptionComponent
