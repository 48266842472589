import React, { useEffect, useState, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  InputLabel,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';

import firebaseApp from '../../firebase';
import BackDrop from '../shared/backdrop.component';
import MainContext from '../state/main.context';
import ErrorDialog from '../shared/error.dialog.component';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3),
    marginTop: theme.spacing(12),
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90vw',
    margin: theme.spacing(2)
  },
  inputFields: {
    margin: theme.spacing(1)
  },
  inputField: {
    minWidth: 250,
    margin: theme.spacing(2)
  },
  expenseBtn:  {
    margin: theme.spacing(2)
  },
  sendBtn: {
    marginLeft: theme.spacing(5),
    cursor: 'pointer'
  },
  sent: {
    textAlign: 'center',
    color: 'green',
    fontWeight: 'bold',
    margin: theme.spacing(2)
  }
}))

const TaxComponent = props => {
  const componentRef = useRef();
  let { uid } = props;
  const classes = useStyles();
  const context = useContext(MainContext);
  let { mainState } = context;
  const [ loading, setLoading ] = useState(true);
  const [ families, setFamilies ] = useState([]);
  const [ income, setIncome ] = useState([]);
  const [ familySelected, setFamilySelected ] = useState(null);
  const [ taxYear, setTaxYear ] = useState("");
  const [ taxYears, setTaxYears] = useState([]);
  const [ showError, setShowError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(false);
  const [ sent, showSent ] = useState(false);

  useEffect(() => {

      const familiesListener = firebaseApp.firestore().collection('users').doc(uid)
        .collection('families').onSnapshot(snapshot => {

          if(snapshot.size > 0) {
            let tempArr = [];
            snapshot.forEach(snap => {
              let tempData = snap.data();
              tempData.ref = snap.id;
              tempArr.push(tempData)
            })
            setFamilies([...tempArr]);
            setLoading(false);
            return;
          }
          setFamilies([]);
          setLoading(false);
        }, error => {
          console.log('ERROR',error);
          setLoading(false);
        })

      return () => familiesListener();

  },[])

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    let year = (e.target[0].value).trim();
    setTaxYear(year);
    setLoading(true);

    firebaseApp.firestore().collection('users').doc(uid)
      .collection('income').where('year', '==', Number(year)).get()
      .then(snapshot => {
        
        if(snapshot.size > 0) {
          let arr = snapshot.docs.map(doc => {
            let data = doc.data();
            data.dateInMilli = new Date(data.date).getTime();
            return data;
          });
          arr.sort((a,b) => a.dateInMilli - b.dateInMilli);
          setIncome(arr);
        } else {
          setIncome([]);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log('ERROR',err);
        setLoading(false);
      })
      console.log(income);
  }

  const TaxesComponent = () => {

    return (
      <div style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        border: '1px solid lightgrey'
      }}>

        <div style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          border: '1px solid lightgrey'
        }}>

          {

            income.reduce((accumulator,currentValue) => {
              if(!accumulator.includes(currentValue.family.family)) {
                accumulator.push(currentValue.family.family);
              }
              return accumulator;
            },[])
            .map(family =>
              <Button
                key={family}
                color='primary'
                onClick={() => setFamilySelected(family)}
              >{family}</Button>
            )

          }

        </div>

        <div style={{
          flexGrow: 5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid lightgrey'
        }}>

        {
          familySelected && (
            <TaxInfo />
          )
        }

        </div>

      </div>
    )
  }

  const TaxInfo = () => (
    <div>
      <ReactToPrint
        trigger={() =>
          <PrintIcon
            style={{
              marginTop: '25px',
              right: '25px',
              cursor: 'pointer'
            }}
          />
        }
        content={ () => componentRef.current }
      />

      <Button
        color='secondary'
        className={classes.sendBtn}
        endIcon={<SendIcon />}
        onClick={sendTaxInfo}
      >
        Email to Family
      </Button>

      {
        sent && (
          <Typography className={classes.sent}>Sent!</Typography>
        )
      }

      <div style={{paddingTop: '25px'}}
        ref={componentRef}
      >

        <h2 style={{textAlign: 'center'}}>

        </h2>
        <h3 style={{textAlign: 'center'}}>
          {
            mainState.providerInfo ?
              mainState.providerInfo.company
            : null
          }
        </h3>
        <h4 style={{textAlign: 'center'}}>Tax Year {taxYear}</h4>

        <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Check#/Payment Type</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {
              income.filter(item => item.family.family == familySelected)
                .map((item,key) =>
                  <TableRow key={key}>
                    <TableCell>{item.date}</TableCell>
                    <TableCell>{item.check_number}</TableCell>
                    <TableCell>${Number(item.amount).toFixed(2)}</TableCell>
                  </TableRow>
                )
            }

            <TableRow>
              <TableCell rowSpan={1} />
              <TableCell colSpan={1} style={{fontWeight:'bold'}}>Total</TableCell>
              <TableCell style={{fontWeight:'bold'}}>
                ${
                  income.reduce((accumulator, currentValue) => {
                    if(currentValue.family.family == familySelected) {
                      accumulator = (Number(accumulator) + Number(currentValue.amount)).toFixed(2)
                    }
                    return accumulator;
                  },0)
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

      </div>
    </div>
  )

  const confirmSent = () => {
    showSent(true);
    setTimeout(() => {
      showSent(false);
    },3000);
  }

  const sendTaxInfo = async () => {
    setLoading(true);
    let obj = {};
    obj.taxYear = taxYear;
    obj.provider = mainState.providerInfo.company;
    obj.income = income.filter(item => {
      if(item.family.family === familySelected){
        delete item._id;
        return item;
      }
    });

    let options = {
      method: 'Post',
      headers: {
        Authorization: mainState.authUser.uid
      },
      body: JSON.stringify(obj)
    }
    let url = "https://us-central1-munchkin-notes.cloudfunctions.net/sendTaxDocument";

    try {

      let initSendMessage = await fetch(url,options);
      let textRes = await initSendMessage.text();
      console.log('RESPONSE: ', textRes);
      setLoading(false);
      if(!initSendMessage.ok) {
        setErrorMessage(textRes);
        setShowError(true);
        return;
      }
      confirmSent();

    } catch (e) {
      console.log('ERROR',e);
      setLoading(false);
      setErrorMessage("THERE WAS AN ERROR SENDING THE EMAIL.  PLEASE TRY AGAIN LATER.");
      setShowError(true);
    }

  }

  return (
    <div className={classes.root}>

      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField
          label={"Enter Tax Year (i.e. " + (new Date().getUTCFullYear() - 1) + ")"}
          className={classes.inputField}
          variant="outlined"
        />
        <Button
          variant='contained'
          color='primary'
          type='submit'
        >
          Retrieve Tax Year
        </Button>
      </form>

      {
        income.length > 0 && (
          <TaxesComponent />
        )
      }

      <ErrorDialog toOpen={showError} message={errorMessage} />
      <BackDrop loading={loading} />
    </div>
  )
}

export default TaxComponent
