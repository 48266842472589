import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Typography,
  Drawer,
  Select,
  MenuItem,
  Divider
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import MaterialTable from 'material-table';

import AddChildComponent from './add_child.component';
import ChildrenTableComponent from './children.component';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(3),
    marginTop: theme.spacing(10),
  },
  addBtn: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    margin: theme.spacing(2)
  },
  inputFields: {
    margin: theme.spacing(1)
  },
  submitBtn:  {
    margin: theme.spacing(2)
  },
  mainFamilyCont: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'lightgrey'
  },
  familyCont: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(2),
    overflow: 'auto'
  },
  familyContLeft: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    margin: theme.spacing(1)
  },
  familyContRight: {
    flexGrow: 1,
    margin: theme.spacing(1)
  },
  addChild: {
    alignSelf: 'flex-end',
    margin: theme.spacing(1)
  }
}))

const FamilyComponent = ({family,uid}) => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ addChild, setAddChild ] = useState(false);

  return (
    <div className={classes.mainFamilyCont}>

      <Button
        variant="contained"
        color="secondary"
        className={classes.addChild}
        onClick={() => setAddChild(!addChild)}
      >
        {addChild ? 'Close Form' : 'Add Child' }
      </Button>

      {
        addChild && (
          <AddChildComponent family={family} uid={uid} />
        )
      }

      <div className={classes.familyCont}>
        <div className={classes.familyContLeft}>
          <Typography>CONTACT</Typography>
          <Typography>Parent 1: { family.parent1_first + ' ' + family.parent1_last }</Typography>
          <Typography>Parent 2: { family.parent2_first + ' ' + family.parent2_last }</Typography>
          <Typography>Primary Phone: { family.primary_phone }</Typography>
          <Typography>Secondary Phone: { family.secondary_phone }</Typography>
          <Typography>Primary Work: { family.primary_work_phone }</Typography>
          <Typography>Secondary Work: { family.secondary_work_phone }</Typography>
          <Typography>Primary Email: { family.primary_email }</Typography>
          <Typography>Secondary Email: { family.primary_email }</Typography>

          <Divider />

          <Typography>ADDRESS</Typography>
          <Typography>{family.street_addr}</Typography>
          <Typography>{family.apt_addr}</Typography>
          <Typography>{family.city_addr}</Typography>
          <Typography>{family.state_addr}</Typography>
          <Typography>{family.zip_addr}</Typography>
        </div>

        <div className={classes.familyContRight}>
          <ChildrenTableComponent family={family} uid={uid} />
        </div>
      </div>
    </div>
  )
}

export default FamilyComponent
