import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Drawer,
  List,
  Divider,
  TextField,
  Button,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Autocomplete from '@material-ui/lab/Autocomplete';

import MainContext from '../state/main.context';
import firebaseApp from '../../firebase';
import ErrorDialog from '../shared/error.dialog.component';
import BackDrop from '../shared/backdrop.component';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3),
    marginTop: theme.spacing(15),
  },
  addBtn: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    border: '1px solid lightgrey',
    borderRadius: '5px'
  },
  inputFields: {
    margin: theme.spacing(1)
  },
  submitBtn:  {
    margin: theme.spacing(2)
  }
}))

const ProviderFormComponent = ({authUser}) => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ stateSelected, selectState ] = useState(null);
  const [ showError, setShowError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const states = [
    'AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'
  ];


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    if(!stateSelected) {
      initShowError('Please select a state.');
      return;
    }
    setLoading(true);
    let obj = {};
    obj.first_name = e.target[0].value;
    obj.last_name = e.target[1].value;
    obj.email = e.target[2].value;
    obj.phone = e.target[3].value;
    obj.company = e.target[4].value;
    obj.address = {
      street: e.target[5].value,
      city: e.target[6].value,
      zip: e.target[7].value,
      state: stateSelected
    }

    let options = {
      method: 'Post',
      headers: {
        Authorization: authUser.uid
      },
      body: JSON.stringify(obj)
    }
    let url = "https://us-central1-munchkin-notes.cloudfunctions.net/createNewUser";

    try {

      let initCreateUser = await fetch(url,options);
      let textRes = await initCreateUser.text();
      console.log(textRes);
      setLoading(false);
      e.target.reset();
      if(initCreateUser.ok) {
        return;
      }

      initShowError(textRes);

    } catch (e) {
      console.log('ERROR',e);
      setLoading(false);
      e.target.reset();
      initShowError('THERE WAS AN ISSUE CREATING USER.');
    }

  }

  const initShowError = message =>  {
    setErrorMessage(message);
    setShowError(true);
    setTimeout(() => setShowError(false),2000);
  }

  return (
    <div className={classes.root}>

      <form onSubmit={handleSubmit}
        className={classes.form}
      >
        <TextField
          type='text'
          label='First Name'
          name='first_name'
          className={classes.inputFields}
          required
        />

        <TextField
          type='text'
          label='Last Name'
          name='last_name'
          className={classes.inputFields}
          required
        />

        <TextField
          type='email'
          label='Email'
          name='email'
          className={classes.inputFields}
          required
        />

        <TextField
          type='text'
          label='Phone Number'
          name='phone'
          className={classes.inputFields}
          required
        />

        <TextField
          type='text'
          label='Company Name'
          name='company'
          className={classes.inputFields}
          required
        />

        <TextField
          multiline
          rows={3}
          type='text'
          label='Street Address'
          name='street_addr'
          className={classes.inputFields}
          required
        />

        <TextField
          type='text'
          label='City'
          name='city_addr'
          className={classes.inputFields}
          required
        />

        <TextField
          type='text'
          label='Zip'
          name='zip_addr'
          className={classes.inputFields}
          required
        />

        <Autocomplete
          required
          options={states}
          getOptionsLabel={option => option}
          className={classes.inputFields}
          renderInput={(params) => <TextField { ...params } label="State" variant='outlined' />}
          onChange={e => {
            selectState(e.target.textContent)
          }}
        />

        <Button
          variant="contained"
          color="primary"
          type='submit'
          className={classes.submitBtn}
        >
          Add Provider
        </Button>
      </form>

      <ErrorDialog message={errorMessage} toOpen={showError} />
      <BackDrop loading={loading} />
    </div>
  )
}

export default ProviderFormComponent
