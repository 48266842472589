import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemText
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import MainContext from '../state/main.context';
import HeaderComponent from '../shared/header.component';
import firebaseApp from '../../firebase';
import ExpensesComponent from './expenses.component';
import FamiliesComponent from './families.component';
import IncomeComponent from './income.component';
import TaxComponent from './tax.component';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    // border: '1px solid blue'
  },
  menuButton: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    margin: theme.spacing(1),
    marginTop: theme.spacing(10)
  },
  list: {
    marginTop: theme.spacing(10)
  },
  drawer: {
    marginTop: theme.spacing(5),
  }
}))

const DefaultHomeComponent = props => {
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ provider, setProvider ] = useState(null);
  const [ page, showPage ] = useState('Expenses')
  const [ menuOpen, toggleMenu ] = useState(false);
  const menuOptions = [
    'Income',
    'Expenses',
    'Families',
    'Tax Info'
  ];

  useEffect(() => {
    if(mainState.authUser && !provider) {

      firebaseApp.firestore().collection('users').doc(mainState.authUser.uid)
        .get().then(snapshot => {
          setProvider(snapshot.data());
          mainDispatch({ type: 'setProviderInfo', payload: snapshot.data() });
        })
        .catch(error => {
          console.log('ERROR',error);
        })

    }
  },[ mainState ])

  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        {menuOptions.map((text) => (
          <div key={text}>
            <ListItem
              button
              onClick={() => showPage(text)}
              key={text}
              selected={page === text}
            >
              <ListItemText primary={text} />
            </ListItem>
            <Divider />
          </div>
        ))}


      </List>


    </div>
  );

  const toggleDrawer = () => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    toggleMenu(!menuOpen)
  };

  return (
    <div className={classes.root}>
      <HeaderComponent title={ provider ? provider.company : '' } />

      <IconButton edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer()}
      >
        <MenuIcon />
      </IconButton>

      <Drawer open={menuOpen} onClose={toggleDrawer()} className={classes.drawer}>
        {sideList()}
      </Drawer>

      <div>
      {
        (page === 'Expenses' && mainState.authUser ) && (
          <ExpensesComponent uid={ mainState.authUser.uid }/>
        )
      }

      {
        (page === 'Families' && mainState.authUser ) && (
          <FamiliesComponent uid={ mainState.authUser.uid }/>
        )
      }

      {
        (page === 'Income' && mainState.authUser ) && (
          <IncomeComponent uid={ mainState.authUser.uid }/>
        )
      }

      {
        (page === 'Tax Info' && mainState.authUser ) && (
          <TaxComponent uid={ mainState.authUser.uid }/>
        )
      }

      </div>

    </div>
  )
}

export default DefaultHomeComponent
