import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Typography,
  Drawer,
  Select,
  MenuItem,
  Divider
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';

import MainContext from '../state/main.context';
import HeaderComponent from '../shared/header.component';
import firebaseApp from '../../firebase';
import MaterialTable from 'material-table';
import BackDrop from '../shared/backdrop.component';
import FamilyComponent from './family.component';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(3),
    marginTop: theme.spacing(12),
  },
  addBtn: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    margin: theme.spacing(2)
  },
  inputFields: {
    margin: theme.spacing(1)
  },
  submitBtn:  {
    margin: theme.spacing(2)
  },
  mainFamilyCont: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'lightgrey'
  },
  familyCont: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(2),
    overflow: 'auto'
  },
  familyContLeft: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    margin: theme.spacing(1)
  },
  familyContRight: {
    flexGrow: 1,
    margin: theme.spacing(1)
  },
  addChild: {
    alignSelf: 'flex-end',
    margin: theme.spacing(1)
  }
}))

const FamiliesComponent = props => {
  let { uid } = props;
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;
  let { authUser } = mainState;
  const classes = useStyles();
  const [ loading, setLoading ] = useState(true);
  const [ families, setFamilies ] = useState([]);
  const [ showForm, toggleForm ] = useState(false);
  const states = [
    'AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'
  ]

  useEffect(() => {
    const expenseListener = firebaseApp.firestore().collection('users').doc(uid)
      .collection('families').onSnapshot(snapshot => {

        if(snapshot.size > 0) {
          let tempArr = [];
          snapshot.forEach(snap => {
            let tempData = snap.data();
            tempData.ref = snap.id;
            tempArr.push(tempData)
          })
          setFamilies(tempArr);
        }
        setLoading(false);
      }, error => {
        console.log('ERROR',error);
        setLoading(false);
      })

      return () => expenseListener();
  },[])

  const columns = [
    {
      title: 'Family',
      field: 'parent1_last',
      defaultSort: 'desc',
      render: rowData => <Typography>{rowData.parent1_last} Family</Typography>
    }
  ]

  const handleSubmit = async (e) => {

    e.preventDefault();
    e.persist();
    setLoading(true);

    let data = {};
    data.parent1_first = e.target[0].value;
    data.parent1_last = e.target[1].value;
    data.parent2_first = e.target[2].value;
    data.parent2_last = e.target[3].value;
    data.primary_phone = e.target[4].value;
    data.secondary_phone = e.target[5].value;
    data.primary_work_phone = e.target[6].value;
    data.secondary_work_phone = e.target[7].value;
    data.primary_email = e.target[8].value;
    data.secondary_email = e.target[9].value;
    data.street_addr = e.target[10].value;
    data.city_addr = e.target[11].value;
    data.zip_addr = e.target[12].value;
    data.state_addr = e.target[13].value;
    data.uid = uid;
    data.dateCreated = new Date().toString();

    firebaseApp.firestore().collection('users').doc(uid)
      .collection('families').add(data)
      .then(doc => {
        e.target.reset();
        setLoading(false);
      })
      .catch(err => {
        console.log('ERROR',err);
        e.target.reset();
        setLoading(false);
      })

  }

  const initDeleteEntry = (rowData) => {
    setLoading(true);

    firebaseApp.firestore().collection('users').doc(uid)
      .collection('families').doc(rowData.ref)
      .delete().then(() => {
        setLoading(false);
      })
      .catch(err => {
        console.log('ERROR',err);
        setLoading(false);
      })
  }

  return (
    <div className={classes.root}>

      <Button
        variant='contained'
        color='secondary'
        className={classes.addBtn}
        onClick={() => toggleForm(!showForm)}
      >
        Add Family
      </Button>

      {
        families && (
          <MaterialTable
            width='100%'
            title='Families'
            columns={columns}
            data={families}
            actions={[
              {
                icon: () => <DeleteIcon color='secondary' />,
                tooltip: 'Delete Entry',
                onClick: (event,rowData) => initDeleteEntry(rowData)
              }
            ]}

            detailPanel={rowData => <FamilyComponent family={rowData} /> }
          />
        )
      }

      <Drawer anchor='right' open={showForm}>

      <form
        onSubmit={handleSubmit}
        className={classes.form}
      >
        <TextField
          type='text'
          label='Parent #1 First'
          name='parent1_first'
          placeholder='Enter FIRST Name (required)'
          className={classes.inputFields}
        />
        <TextField
          type='text'
          label='Parent #1 Last'
          name='parent1_last'
          placeholder='Enter LAST Name (required)'
          className={classes.inputFields}
        />

        <TextField
          type='text'
          label='Parent #2 First'
          name='parent2_first'
          placeholder='Enter FIRST Name (optional)'
          className={classes.inputFields}
        />
        <TextField
          type='text'
          label='Parent #2 Last'
          name='parent2_last'
          placeholder='Enter LAST Name (optional)'
          className={classes.inputFields}
        />

        <TextField
          type='text'
          label='Primary Phone #'
          name='primary_phone'
          placeholder='Enter Phone Number (required)'
          className={classes.inputFields}
        />
        <TextField
          type='text'
          label='Secondary Phone #'
          name='secondary_phone'
          placeholder='Enter Phone Number (optional)'
          className={classes.inputFields}
        />

        <TextField
          type='text'
          label='Primary Work Phone #'
          name='primary_work_phone'
          placeholder='Enter WORK Phone Number (required)'
          className={classes.inputFields}
        />
        <TextField
          type='text'
          label='Secondary Work Phone #'
          name='secondary_work_phone'
          placeholder='Enter WORK Phone Number (optional)'
          className={classes.inputFields}
        />

        <TextField
          type='text'
          label='Primary Email'
          name='primary_email'
          placeholder='Enter Email (required)'
          className={classes.inputFields}
        />
        <TextField
          type='text'
          label='Secondary Email'
          name='secondary_email'
          placeholder='Enter Email (optional)'
          className={classes.inputFields}
        />

        <TextField
          multiline
          rows={2}
          type='text'
          label='Street Address'
          name='street_addr'
          placeholder='Enter Street Address'
          className={classes.inputFields}
        />
        <TextField
          type='text'
          label='City'
          name='city_addr'
          placeholder='Enter City'
          className={classes.inputFields}
        />

        <TextField
          type='text'
          label='Zip Code'
          name='zip_addr'
          placeholder='Enter Zip Code'
          className={classes.inputFields}
        />

        <Autocomplete
          options={states}
          getOptionsLabel={option => option}
          className={classes.inputFields}
          renderInput={(params) => <TextField { ...params } label="State" variant='outlined' />}
        />


        <Button
          variant="contained"
          color="primary"
          type='submit'
          className={classes.submitBtn}
        >
          Add Family
        </Button>

        <Button
          variant="contained"
          color="secondary"
          className={classes.submitBtn}
          onClick={() => toggleForm(!showForm)}
        >
          Close
        </Button>

      </form>

      </Drawer>

      <BackDrop loading={loading} />
    </div>
  )
}

export default FamiliesComponent
