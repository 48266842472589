import React, { useState } from 'react';
import HeaderComponent from '../shared/header.component';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingBottom: '10vh',
      paddingTop: '8vh',
      // backgroundColor: '#b5a33f'
  },
  paper1: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5)
  },
  paper: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5)
  },
  videoPaper: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.light
  },
  contact: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontSize: '8vw'
  },
  para: {
    fontSize: '3vw'
  },
  howTitle: {
    alignSelf: 'flex-start'
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
    padding: theme.spacing(2)
  },
  btns: {
    margin: theme.spacing(5),
    fontSize: "1.5em"
  },
  footer: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footerLinks: {
    textDecoration: 'none',
    color: 'white',
    margin: theme.spacing(1)
  },
  rights: {
    margin: theme.spacing(1)
  }
}))

const DemoAndPricing = props => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ confirmMessage, setConfirmMessage ] = useState('');
  const [ purpose, setPurpose ] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();

    if(e.target[6].value) {
      return;
    }

    setLoading(true);

    let obj = {};
    obj.user = e.target[0].value;
    obj.email = e.target[1].value;
    obj.phone = e.target[2].value || "";
    obj.purpose = purpose;
    obj.message = e.target[4].value || "";

    let options = {
      method: 'Post',
      body: JSON.stringify(obj)
    }

    let url = "https://us-central1-munchkin-notes.cloudfunctions.net/sendContactMessage";

    try {

      let initSend = await fetch(url,options);
      if(initSend.ok) {
        setConfirmMessage("Information received.  We'll be in touch soon!");
        setLoading(false);
        setShowConfirm(true);
        e.target.reset();
        return;
      }

    } catch (e) {
      e.target.reset();

      console.log('ERROR',e);

      setLoading(false);

    }

  }

  return (
    <div className={classes.root}>
      <HeaderComponent title="Demo and Pricing" />

      <Paper className={classes.paper1} elevation={5}>
        <Typography
          variant="h2"
          style={{ textAlign: 'center' }}
          color='secondary'
        >A service for in-home daycare providers...</Typography>
      </Paper>

      <Paper className={classes.videoPaper}>
        <iframe width="672" height="378" src="https://www.youtube.com/embed/a4V7a84rd3g" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Paper>

      <Paper className={classes.paper} elevation={5}>
        <Typography variant="h2">Pricing with 2 months Free Trial</Typography>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Typography variant="h4">
            Level 1 - $4.99/mo | For providers who serve up to 8 children.
          </Typography>
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Typography variant="h4">
            Level 2 - $9.99/mo | For providers who serve up to 16 children.
          </Typography>
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Typography variant="h4">
            Level 3 - $24.99/mo | For providers who serve more then 16 children.
          </Typography>
        </ListItem>


      </Paper>



      <Paper className={classes.contact} elevation={5}>

        <Typography variant="h3" className={classes.howTitle}>What do you think?</Typography>

        <div className={classes.btnContainer}>

        <Button
          onClick={() => props.history.push('/signup')}
          className={classes.btns}
          variant="contained"
          color="secondary"
        >Sign me up!</Button>

        <a>
          <Button
            onClick={() => props.history.push('/contact-us')}
            className={classes.btns}
            variant="contained"
            color="primary"
          >Send Feedback</Button>
        </a>

        </div>

      </Paper>

      <Paper className={classes.footer} elevation={5}>
        <a className={classes.footerLinks}
          href="https://docs.google.com/document/d/1yDNF1QPe9Wf2rVDjhAoAzJZoLNS6nYUed-nkmo0HcD0/preview" target="_blank">
          <Typography>Privacy Policy</Typography>
        </a>

        <a className={classes.footerLinks}
          href="https://docs.google.com/document/d/11ZFuK-8-RlajZfD64uG002w0W63VI_W36cXgCUkaooA/preview" target="_blank">
          <Typography>Terms and Conditions</Typography>
        </a>

        <Typography className={classes.rights}>All Rights Reserved to MunchkinNotes, 2020 { new Date().getUTCFullYear() > 2020 ? " - " + new Date().getUTCFullYear() : null}</Typography>
      </Paper>

    </div>
  )
}

export default DemoAndPricing
