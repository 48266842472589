import React, { useState } from 'react';
import HeaderComponent from '../shared/header.component';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import BackDrop from '../shared/backdrop.component';
import ConfirmDialog from '../shared/confirm.dialog.component';

const useStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingBottom: '10vh',
      paddingTop: '8vh',
      // backgroundColor: '#b5a33f'
  },
  paper1: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5)
  },
  paper: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5)
  },
  paperHow: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contact: {
    width: '95%',
    margin: theme.spacing(2),
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontSize: '8vw'
  },
  para: {
    fontSize: '3vw'
  },
  howTitle: {
    alignSelf: 'flex-start'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    alignSelf: 'center'
  },
  inputFields: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1)
  }
}))

const ContactUsComponent = props => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ confirmMessage, setConfirmMessage ] = useState('');
  const [ purpose, setPurpose ] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();

    setLoading(true);

    let obj = {};
    obj.user = e.target[0].value;
    obj.email = e.target[1].value;
    obj.phone = e.target[2].value || "";
    obj.purpose = purpose;
    obj.message = e.target[4].value || "";

    console.log(obj);

    let options = {
      method: 'Post',
      body: JSON.stringify(obj)
    }

    let url = "https://us-central1-munchkin-notes.cloudfunctions.net/sendContactMessage";

    try {

      let initSend = await fetch(url,options);
      if(initSend.ok) {
        setConfirmMessage("Information received.  We'll be in touch soon!");
        setLoading(false);
        setShowConfirm(true);
        e.target.reset();
        return;
      }

    } catch (e) {
      e.target.reset();

      console.log('ERROR',e);

      setLoading(false);

    }

  }

  return (
    <div className={classes.root}>
      <HeaderComponent title="Contact Us" />

      <Paper className={classes.contact} elevation={5}>

        <Typography variant="h3" className={classes.howTitle}>Contact Us!</Typography>

        <form
          onSubmit={handleSubmit}
          className={classes.form}
        >

          <div class="g-recaptcha" data-sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"></div>

          <TextField
            type='text'
            label="Name"
            className={classes.inputFields}
            required
          />
          <TextField
            type='email'
            label="Email Address"
            className={classes.inputFields}
            required
          />
          <TextField
            type='text'
            label="Phone Number (optional)"
            className={classes.inputFields}
          />

          <FormControl className={classes.formControl}>
            <InputLabel>Purpose</InputLabel>
            <Select
              value={purpose}
              onChange={e => setPurpose(e.target.value)}
            >
              <MenuItem value="Learn More">Learn More</MenuItem>
              <MenuItem value="Contact Me">Contact Me</MenuItem>
              <MenuItem value="Concern">Concern</MenuItem>
              <MenuItem value="Suggestion">Suggestion</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>

          <TextField
            type='text'
            multiline
            variant="outlined"
            rows={4}
            label="Optional Message"
            className={classes.inputFields}
          />

          <Button
            variant='contained'
            color='primary'
            type='submit'
            className={classes.inputFields}
          >
            Send
          </Button>
        </form>

      </Paper>

      <ConfirmDialog message={confirmMessage} toOpen={showConfirm} />
      <BackDrop loading={loading} />
    </div>
  )
}

export default ContactUsComponent
