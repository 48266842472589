import React, { useContext, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import HeaderComponent from './header.component';
import BackDrop from './backdrop.component';
import MainContext from '../state/main.context';
import firebaseApp from '../../firebase';
import { SignOut } from '../utilities/firebase.actions';


const windowWidth = window.innerWidth;

const LogInComponent = props => {
  const context = useContext(MainContext);
  let { mainState, mainDispatch } = context;

  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if(mainState.authUser && !mainState.userType) {
      //  CHECK USER CLAIM
      checkUserClaim(mainState.authUser);
    }
  }, [ mainState ])

  const checkUserClaim = async (user) => {
    console.log('Checking User Claim...');

    setLoading(true);

    let options = {
      method: 'Post',
      body: JSON.stringify({
        uid: user.uid
      })
    }

    let url = "https://us-central1-munchkin-notes.cloudfunctions.net/checkUserClaim";

    try {

      let initCheckUser = await fetch(url,options);
      let textRes = await initCheckUser.text();

      if(initCheckUser.ok) {
        mainDispatch({ type: 'setUserType', payload: textRes });
        setLoading(false);
        props.history.push(`/${textRes}`)
        return;
      }

      alert(textRes);
      SignOut();

    } catch (e) {

      console.log('THERE WAS AN ERROR',e);
      setLoading(false);

    }

  } // USER CLAIM

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    setLoading(true);

    let email = e.target[0].value;
    let password = e.target[1].value;
    try {
      let initSignin = await firebaseApp.auth().signInWithEmailAndPassword(email, password);

    } catch (e) {
      console.log('Error',e);

    } finally {
      setLoading(false)
    }

  }

  return (
    <div style={styles.container}>
      <HeaderComponent title='Authenticate' />
      <form onSubmit={handleSubmit} style={windowWidth < 750 ? styles.formSm : styles.form}>
        <TextField
          type='text'
          label='Email'
          name='email'
          placeholder='Enter Email Address'
          style={styles.textfield}
          required
        />
        <TextField
          type='password'
          label='Password'
          name='password'
          placeholder='Enter Password'
          style={styles.textfield}
          required
        />
        <Button
          variant="contained"
          color="secondary"
          type='submit'
          style={styles.submitButton}
        >
          Sign In
        </Button>
      </form>
      <BackDrop loading={loading} />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '10vh'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '40vw'
  },
  formSm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '90vw'
  },
  textfield: {
    alignSelf: 'center',
    width: '80%',
    marginBottom: '5px'
  },
  submitButton: {
    width: '80%',
    marginTop: '10px',
    alignSelf: 'center'
  }
}

export default LogInComponent
