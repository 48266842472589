import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import HeaderComponent from '../shared/header.component';

const useStyles = makeStyles(theme => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: "50vw",
  },
  paper: {
    marginTop: '10vh',
    padding: '20px'
  },
  button: {
    margin: theme.spacing(1),
    width: '45vw',
    alignSelf: 'center'
  },
  dialog: {
    padding: theme.spacing(3)
  },
  dialogMessage: {
    margin: theme.spacing(2)
  },
  dialogBtn: {
    margin: theme.spacing(2)
  }
}))


const ChangePasswordComponent = props => {
  const history = useHistory();
  const params = useParams();
  const classes = useStyles();
  let { uid, email } = params;
  const [ loading, setLoading ] = useState(false);
  const [ pass1, setPass1 ] = useState(null);
  const [ pass2, setPass2 ] = useState(null);
  const [ showSuccess, setShowSuccess ] = useState(false);
  const [ showDialog, setShowDialog ] = useState(false);
  const [ dialogTitle, setDialogTitle ] = useState('Hello');
  const [ dialogMessage, setDialogMessage ] = useState('This is a message.');
  const [ showError, setShowError ] = useState(false);
  const [ response, setResponse ] = useState('');

  useEffect(() => {},[showDialog])


  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if(pass1 !== pass2) {
      setShowError(true);
      setLoading(false);
      return;
    }
    setShowError(false);

    let options = {
      method: 'Post',
      body: JSON.stringify({
        uid: uid,
        password: pass1
      })
    }

    let url = "https://us-central1-munchkin-notes.cloudfunctions.net/confirmAndSetAccountPassword";

    try {

      let initconfirmAccount = await fetch(url,options);
      let textRes = await initconfirmAccount.text();

      if(initconfirmAccount.ok) {
        setDialogTitle('Successful');
        setDialogMessage('Successfully verified your account.  An email is on its way!');
        setShowDialog(true);
        setLoading(false);
        return;
      }

      setDialogTitle('Error');
      setDialogMessage('There was an issue verifying account.  Please contact an administrator.');
      setShowDialog(true);
      setLoading(false);

    } catch (e) {
      console.log('ERROR',e);
      setDialogTitle('Error');
      setDialogMessage('There was an issue verifying account.  Please contact an administrator.');
      setShowDialog(true);
      setLoading(false);

    }

  }

  const handleClose = () => {
    setShowDialog(false);
    history.push('/');
  }

  return (
    <div>

    <HeaderComponent title='Confirm Account | Change Password' />

    <div className={classes.formContainer}>
      <Paper className={classes.paper}>
      <Typography className={classes.title}>
        { email.toUpperCase() }
      </Typography>
      <Typography className={classes.title}>

      </Typography>
      <form onSubmit={ (event)=> submit(event) } className={classes.form}>

        <TextField
          error={showError}
          helperText={showError ? 'Passwords do not match.' : null}
          id='pass_1'
          label='Enter Password'
          type='password'
          margin='normal'
          onChange={e => setPass1(e.target.value.trim())}
          required={true}
        />

        <TextField
          error={showError}
          helperText={showError ? 'Passwords do not match.' : null}
          id='pass_2'
          label='Confirm Password'
          type='password'
          margin='normal'
          onChange={e => setPass2(e.target.value.trim())}
          required={true}
        />


        <Button
          type='submit'
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={ loading || !pass1 || !pass2 }
        >
          Confirm Account | Change Password
        </Button>

        {
          showSuccess ?
            <p style={{
              textAlign: 'center',
              color: 'green'
            }}>{response}</p>
          : null
        }
      </form>
      </Paper>
    </div>

    <Dialog onClose={handleClose} open={showDialog} className={classes.dialog}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText >
          {dialogMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          className={classes.dialogBtn}
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>

    </div>
  )
}

export default ChangePasswordComponent
